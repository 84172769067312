"use client";

import { Canvas } from "@react-three/fiber";
import { Box, OrbitControls, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useMemo } from "react";
import Experience from "./components/Experience";
import Test from "./components/Test";
import Custom from "./components/custom/CustomBillboard";
import Test1 from "./components/test1/test1";

function RoundSprite({ color, fixed, renderOrder, ...props }) {
  const texture = useTexture("/images/test1.png");

  const shaderMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Color(color) },
        map: { value: texture },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 color;
        uniform sampler2D map;
        varying vec2 vUv;
        void main() {
          vec2 uv = vUv - 0.5;
          float r = length(uv);
          if (r > 0.5) {
            discard;
          }
          vec4 texColor = texture2D(map, vUv);
          gl_FragColor = vec4(color, 1.0) * texColor;
          gl_FragColor.a *= 1.; // Make the sprite slightly transparent
        }
      `,
      transparent: true,
      depthWrite: false, // Disable depth writing
      depthTest: !fixed, // Disable depth testing for fixed sprites
      side: THREE.DoubleSide, // Make the sprite double-sided
    });
  }, [color, texture, fixed]);

  shaderMaterial.renderOrder = renderOrder;

  return (
    <sprite {...props} renderOrder={renderOrder}>
      <primitive object={shaderMaterial} attach="material" />
    </sprite>
  );
}

export default function Component() {
  return <Test1 />;
  // return <Custom />;
  // return <Experience />;
  // return (
  //   <div
  //     style={{
  //       width: "100%",
  //       height: "100vh",
  //     }}
  //   >
  //     <Canvas camera={{ position: [0, 0, 5] }}>
  //       <ambientLight intensity={0.5} />
  //       <pointLight position={[10, 10, 10]} />
  //       <RoundSprite
  //         color="red"
  //         position={[-1, 0, 0]}
  //         fixed={true}
  //         renderOrder={3}
  //         scale={[1, 1, 1]}
  //       />
  //       <RoundSprite
  //         color="blue"
  //         position={[0, 0, -1]}
  //         fixed={true}
  //         renderOrder={2}
  //         scale={[1.5, 1.5, 1.5]}
  //       />
  //       <RoundSprite
  //         color="green"
  //         position={[1, 0, 1]}
  //         fixed={true}
  //         renderOrder={1}
  //         scale={[2, 2, 2]}
  //       />
  //       <Box
  //         position={[0, 0, 2]}
  //         scale={[0.5, 0.5, 0.5]}
  //         material={new THREE.MeshBasicMaterial({ color: "black" })}
  //       />
  //       <OrbitControls />
  //     </Canvas>
  //   </div>
  // );
}
