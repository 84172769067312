import React, { useRef, useMemo } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, Grid } from "@react-three/drei";
import * as THREE from "three";

const RotatingPlane = ({ position = [0, 0, 0], targetPoint = [0, 0, 10] }) => {
  const planeRef = useRef(null);
  const { camera } = useThree();

  // Create the arrow texture
  const arrowTexture = useMemo(() => {
    const canvas = document.createElement("canvas");
    canvas.width = 256;
    canvas.height = 256;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.fillStyle = "skyblue";
      ctx.fillRect(0, 0, 256, 256);
      ctx.fillStyle = "black";
      ctx.beginPath();
      ctx.moveTo(128, 32);
      ctx.lineTo(224, 224);
      ctx.lineTo(32, 224);
      ctx.closePath();
      ctx.fill();
    }
    return new THREE.CanvasTexture(canvas);
  }, []);

  const target = useMemo(
    () => new THREE.Vector3(...targetPoint),
    [targetPoint]
  );

  useFrame(() => {
    if (planeRef.current) {
      // Make the plane face the camera
      planeRef.current.lookAt(camera.position);

      // Calculate the direction from the plane to the target point
      const planePosition = planeRef.current.position;
      const directionToTarget = new THREE.Vector3()
        .subVectors(target, planePosition)
        .normalize();

      // Project the direction onto the plane
      const planeNormal = planeRef.current.getWorldDirection(
        new THREE.Vector3()
      );
      const projectedDirection = new THREE.Vector3()
        .copy(directionToTarget)
        .projectOnPlane(planeNormal)
        .normalize();

      // Calculate the angle between the projected direction and the plane's up vector
      const planeUp = new THREE.Vector3(0, 1, 0).applyQuaternion(
        planeRef.current.quaternion
      );
      let angle = planeUp.angleTo(projectedDirection);

      // Determine if we need to flip the angle
      const cross = new THREE.Vector3().crossVectors(
        planeUp,
        projectedDirection
      );
      if (cross.dot(planeNormal) < 0) {
        angle = -angle;
      }

      // Set the texture rotation
      if (planeRef.current.material instanceof THREE.MeshBasicMaterial) {
        planeRef.current.material.map.center.set(0.5, 0.45);
        planeRef.current.material.map.rotation = angle;
        planeRef.current.material.needsUpdate = true;
      }
    }
  });

  return (
    <>
      <mesh ref={planeRef} position={position}>
        {/* <planeGeometry args={[4, 4]} /> */}
        <circleGeometry args={[2, 32]} />
        <meshBasicMaterial
          map={arrowTexture}
          opacity={1}
          side={THREE.DoubleSide}
        />
      </mesh>
      <mesh position={target}>
        <sphereGeometry args={[0.1, 32, 32]} />
        <meshBasicMaterial color="red" />
      </mesh>
    </>
  );
};

const Test1 = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Canvas camera={{ position: [0, 6, 10] }}>
        <OrbitControls />
        <Grid infiniteGrid />
        <axesHelper args={[5]} />
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} intensity={1} />
        <RotatingPlane position={[0, 0, 0]} targetPoint={[0, 0, 10]} />
        {/* <RotatingPlane position={[10, 0, -6]} targetPoint={[10, 0, -10]} /> */}
      </Canvas>
    </div>
  );
};

export default Test1;
