import React, { useRef, useLayoutEffect } from "react";
import { Canvas, useFrame, extend, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Object3D, Vector3, Euler } from "three";

const CustomBillboardImpl = React.forwardRef(
  (
    { children, lockX = false, lockY = false, lockZ = false, follow = true },
    ref
  ) => {
    const billboardRef = useRef(null);
    const { camera } = useThree();
    const targetPosition = new Vector3();
    const initialRotation = new Euler(Math.PI * 0.5, 0, 0, "YXZ");

    useLayoutEffect(() => {
      if (billboardRef.current) {
        billboardRef.current.setRotationFromEuler(initialRotation);
      }
    }, []);

    useFrame(() => {
      if (billboardRef.current) {
        camera.getWorldPosition(targetPosition);

        if (follow) {
          billboardRef.current.lookAt(targetPosition);

          if (lockX) billboardRef.current.rotation.x = initialRotation.x;
          if (lockY) billboardRef.current.rotation.y = initialRotation.y;
          if (lockZ) billboardRef.current.rotation.z = initialRotation.z;
        }
      }
    });

    return <group ref={billboardRef}>{children}</group>;
  }
);

CustomBillboardImpl.displayName = "CustomBillboardImpl";

extend({ CustomBillboardImpl });

export function CustomBillboard(props) {
  return (
    <Canvas>
      <OrbitControls />
      <CustomBillboardImpl {...props} />
    </Canvas>
  );
}

export default function Custom() {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Canvas>
        <OrbitControls />
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <CustomBillboardImpl lockZ>
          <mesh>
            <planeGeometry args={[1, 1]} />
            <meshStandardMaterial color="royalblue" side={2} />
          </mesh>
        </CustomBillboardImpl>
        <gridHelper args={[10, 10]} />
      </Canvas>
    </div>
  );
}
